import React from "react";
import ContactForm from "../components/ContactForm";
import PageHeader from "../components/PageHeader";

const AboutMe = () => {
  return (
    <div className="about-me-page bg-primary">
      <PageHeader rootPageId="about-me" rootPageName="About Me" />
      <div className="container">
        <div className="row gy-5 py-5 mx-auto">
          <div className="col-12">
            <div className="d-flex flex-column text-white justify-content-center">
              <h1 className="text-center fs-1 fw-bold mb-3">Hi there, I'm Connor</h1>
              <p>
                I am a software developer specializing in UI/UX design, based in
                Brisbane, Australia, with a passion for creating engaging
                experiences through my designs. I am always eager to learn and
                enjoy collaborating with like-minded creatives.
              </p>
              <p>
                My game design course at QUT equipped me with valuable knowledge
                of UI/UX design processes, concepts, and theories that I have
                successfully applied to my work on various websites and mobile
                apps. I particularly enjoy working on projects from end to end,
                taking pride in delivering polished products that exceed client
                expectations.
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <img
                src="/images/About-Me-Image.jpg"
                className="img-fluid w-50"
                alt="About Me"
              />
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default AboutMe;
