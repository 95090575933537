import { useState, useEffect } from "react";
import TextTransition, { presets } from "react-text-transition";

const ROLES = ["developer", "designer", "artist"];
const COLOURS = ["#4E5A9D", "#602297", "#0B6E4F"];

const Background = () => {
  const [roleIndex, setRoleIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => { 
        setRoleIndex((index) => (index + 1) % ROLES.length)
      },
      3000 // every 3 seconds
    );

    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="background-container d-flex">
      <div className="header d-flex flex-column text-white">
        <span className="title">
          Hi! As a{" "}
          <TextTransition
            style={{
              background: COLOURS[roleIndex % COLOURS.length],
            }}
            direction="down"
            inline
            springConfig={presets.stiff}
          >
            {ROLES[roleIndex % ROLES.length]}
          </TextTransition>
          ,
        </span>
        <span className="title">
          I specialize in crafting seamless experiences
        </span>

        <div className="d-flex flex-row justify-content-center my-5">
          <a
            className="btn btn-danger me-4"
            href="../documents/Connor-McCaffrey-Resume.pdf"
          >
            Download Resume
          </a>
          <a className="btn btn-outline-light" href="/app-development">
            View Projects
          </a>
        </div>
      </div>
    </div>
  );
};

export default Background;
