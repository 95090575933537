import React from "react";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
    return (
        <div className="contact-us-page my-2">
            <ContactForm /> 
        </div>
    )
}

export default ContactUs;