export const appDevelopmentData = [
    {
        id: "grapevine",
        title: "Grapevine",
        description: "Grapevine is a suicide prevention-focused React Native app with dynamic data modification using JSON and Markdown and Azure for content configuration.",
        tags: ["React Native", "Front-end", "Back-end", "UI/UX Design"],
        url: ""
    },
    {
        id: "masterbeef",
        title: "Masterbeef",
        description: "Masterbeef is a React Native app focused on animal data, showcasing information about tenants and their animals.",
        tags: ["React Native", "Front-end", "Back-end", "UI/UX Design"],
        url: ""
    },
    {
        id: "movie-mania",
        title: "Movie Mania",
        description: "Movie Mania is an fictional movie library app for browsing, watching, and managing movie collections.",
        tags: ["Power App", "Front-end", "Back-end", "UI/UX Design"],
        url: ""
    },
    {
        id: "maynooth",
        title: "Maynooth",
        description: "Maynooth is a fictional user-friendly furniture browsing platform for effortless furniture discovery and purchase.",
        tags: ["Power App", "Front-end", "Back-end", "UI/UX Design"],
        url: ""
    },
    {
        id: "playbox",
        title: "PlayBox",
        description: "PlayBox is a fictional game library app that provides users with an intuitive platform for browsing and managing their video game collection.",
        tags: ["Power App", "Front-end", "Back-end", "UI/UX Design"],
        url: ""
    },
    {
        id: "task-mate",
        title: "Task Mate",
        description: "Task Mate is a fictional task management app with an intuitive interface and real-time SharePoint integration.",
        tags: ["Power App", "Sharepoint", "Front-end", "Back-end", "UI/UX Design"],
        url: ""
    }
]