export const sectionData = [
    {
        id: "app-development",
        title: "App Development",
        content: "In my recent web development projects, I have gained extensive experience in React, React Native, and Asp.Net. My expertise includes building the UI from scratch, based on UI/UX mockups that I create using Adobe XD. I am also proficient in utilizing WebGL technology, specifically Babylon.js and Three.js. Additionally, I possess a solid foundation in back-end development, with a focus on relational databases.",
        buttonUrl: "app-development",
        imageUrl: "/images/App-Development.png",
        backgroundColor: "info",
        orderByContent: true, 
    },
    {
        id: "ui-ux-mockups",
        title: "UI/UX Mockups",
        content: "With my passion for designing visually appealing and user-friendly experiences, I am highly skilled in UI/UX design. I have a proven track record of creating engaging and intuitive user interfaces, starting from conceptualizing and prototyping to delivering high-fidelity designs. My expertise includes using industry-standard design tools such as Adobe XD, to ensure that the designs are both aesthetically pleasing and highly functional.",
        buttonUrl: "ui-ux-mockups",
        imageUrl: "/images/ui-ux-mockups.png", 
        backgroundColor: "secondary",
        orderByContent: false, 
    },
    {
        id: "game-design",
        title: "Game Design",
        content: "I leverage my proficiency in Unity to build compelling 2D/3D games, utilizing its game engine and scripting capabilities. Moreover, I am skilled in building 3D models using Blender, enabling me to create high-quality and visually appealing game assets. Additionally, I am experienced in drawing pixel art using Aseprite, which allows me to produce engaging and retro-style graphics that add character and charm to my games.",
        buttonUrl: "game-design",
        imageUrl: "/images/game-design.png", 
        backgroundColor: "success",
        orderByContent: true, 
    },
]