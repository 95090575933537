import axios from "axios";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from 'rehype-raw';
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import ContactForm from "../components/ContactForm";
import PageHeader from "../components/PageHeader";

interface ProjectProps {
    projectId: string;
    projectName: string;
    projectTypeId: string;
    projectTypeName: string;
}

const Project: React.FC<ProjectProps> = ({ projectId, projectName, projectTypeId, projectTypeName }) => { 
    const [mdContent, setMdContent] = useState("");

    useEffect(() => {
        const fetchMdContent = async () => {
            try {
                const response = await axios.get(`/markdown/${projectTypeId}/project-${projectId}.md`)
                setMdContent(response.data)
            } catch (error) {
                console.error("Error fetching md content", error)
            }
        };

        fetchMdContent();
    }, [projectId, projectTypeId])

    const transformImageUri = (uri: string) => {
        const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL || "";
        return `${baseUrl}${uri}`;
    };

    return (
        <div className="project-page bg-primary">
            <PageHeader 
                rootPageId={projectTypeId} 
                rootPageName={projectTypeName} 
                childPageId={projectId} 
                childPageName={projectName} 
                isChildHeader={true} 
            />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="py-5 text-white">
                            <ReactMarkdown 
                                children={mdContent} 
                                remarkPlugins={[remarkGfm, remarkBreaks]} 
                                rehypePlugins={[rehypeRaw]}
                                components={{ 
                                    h1: 'h2',
                                    p: ({node, ...props}) => <p className="py-2" {...props} />,
                                    img: ({node, ...props}) => <img className="img-fluid mx-auto d-block" {...props} alt="" />
                                }} 
                                transformImageUri={transformImageUri}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm />
        </div>
    )
}

export default Project;