import React from "react";

interface PageHeaderProps {
  rootPageId: string;
  rootPageName: string;
  childPageId?: string;
  childPageName?: string;
  isChildHeader?: boolean;
}

const headerImage: { [key: string]: string } = {
  "about-me": "About-Me",
  "app-development": "App-Development",
  "ui-ux-mockups": "UI-UX-Mockups",
  "game-design": "game-design",
};

const PageHeader: React.FC<PageHeaderProps> = ({ rootPageId, rootPageName, childPageId, childPageName, isChildHeader = false }) => {
  
  const imgUrl = `/images/${!isChildHeader
    ? `page-headers/${headerImage[rootPageId]}`
    : `project-headers/${rootPageId}/${childPageId}`}-Header-Image.jpg`;

  const title = !isChildHeader ? rootPageName : childPageName   

  return (
    <div className="header-container h-25-sm">
      <img
        className="img-fluid w-100"
        src={imgUrl}
        alt=""
      />
      <div className="overlay d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex">
          <span className="fs-1 fw-bold text-white">{title}</span>
        </div>
        <div className="d-flex">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-primary rounded-pill mt-4 py-2 px-4">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className={`breadcrumb-item ${!isChildHeader && "active"}`} aria-current={!isChildHeader ?? "page"}>
                {!isChildHeader ? rootPageName : (
                  <a href={`/${rootPageId}`}>{rootPageName}</a>
                )}
              </li>
              {isChildHeader && (
                <li className="breadcrumb-item active" aria-current="page">{childPageName}</li>
              )}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
