import { useEffect, useState } from "react";

const TopButton = () => {
  const [isVisible, setVisibility] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const maxScroll = document.documentElement.scrollHeight;

    if (scrollPosition >= maxScroll / 2.5) {
        setVisibility(true)
    } else {
        setVisibility(false);
    }
  };

  useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const transformX = isVisible ? "0%" : "200%" 

  return (
    <a
      className={`top-button btn btn-danger ${isVisible ? "visible" : ""}`}
      href="#Top"
      style={{
        transform: `translate3d(${transformX}, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
      }}
    >
      <img src="../icons/caret-up-solid.svg" alt="" />
    </a>
  );
};

export default TopButton;
