import { useLocation } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import PageHeader from "../components/PageHeader";
import ProjectCard from "../components/ProjectCard";
import { pageHeaders } from "../constants/PageHeaders";
import { appDevelopmentData } from "../data/appDevelopmentData";
import { gameDevelopmentData } from "../data/gameDesignData";
import { uiUxMockupData } from "../data/uiUxMockupData";

interface Project {
  id: string;
  title: string;
  tags: string[];
  description: string;
  url: string;
}

const Projects = () => {
  const { pathname } = useLocation();
  
  const data: Record<string, Project[]> = {
    "app-development": appDevelopmentData,
    "ui-ux-mockups": uiUxMockupData,
    "game-design": gameDevelopmentData,
  };

  const projectType = pathname.replace(/^./, "") || "app-development";
  const projects = data[projectType] ?? appDevelopmentData;

  return (
    <div className="projects-page bg-primary">
      <PageHeader rootPageId={projectType} rootPageName={pageHeaders[projectType]} />
      <div className="container my-3 my-md-0">
        <div className="row row-cols-1 row-cols-md-2 mx-1 gx-4 gx-md-5 gy-4 gy-md-5 py-3 py-md-5">
          {projects.map(({ id, title, tags, description, url }) => (
            <ProjectCard
              key={`${projectType}-${id}`}
              id={id}
              type={projectType}
              title={title}
              tags={tags}
              description={description}
              url={url}
            />
          ))}
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Projects;
