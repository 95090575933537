import { FaCode, FaDatabase, FaDrawPolygon, FaGamepad } from "react-icons/fa";
import Background from "../components/Background";
import ContactForm from "../components/ContactForm";
import Section from "../components/Section";
import { sectionData } from "../data/sectionData";

const iconSize = 42;
const iconColor = "white";

const skillBadges = [
  {
    name: "UI/UX Design",
    icon: <FaDrawPolygon size={iconSize} color={iconColor} className="pe-2" />,
  },
  {
    name: "Front-End",
    icon: <FaCode size={iconSize} color={iconColor} className="pe-2" />,
  },
  {
    name: "Back-End",
    icon: <FaDatabase size={iconSize} color={iconColor} className="pe-2" />,
  },
  {
    name: "Game Design",
    icon: <FaGamepad size={iconSize} color={iconColor} className="pe-2" />,
  },
];

const Home = () => {
  return (
    <div className="App">
      <Background />
      <section className="bg-primary">
        <div className="container">
          <div className="py-5">
            <div className="row justify-content-center text-white mx-auto pb-5 gy-3">
              <div className="col-12 text-md-start">
                <span className="fs-3 fw-bold">
                  Hi, my name is Connor McCaffrey
                </span>
              </div>
              <div className="col-12 text-start">
                <p>
                  I'm a software developer from sunny Brisbane, Australia, who's
                  all about crafting visually stunning and user-friendly
                  experiences. My expertise lies in front-end development, UI/UX
                  design, and WebGL technology, honed through years of hands-on
                  experience. Currently on the lookout for an exciting software
                  development role where I can put my skills in front-end and
                  UI/UX design to good use, helping clients achieve their goals.
                  I thrive on creating interfaces that not only look great but
                  also make life easier for users, driving growth and sparking
                  innovation in the process.
                </p>
              </div>
            </div>
            <div className="row justify-content-center justify-content-md-start mx-auto gy-4">
              {skillBadges.map((item, index) => (
                <div key={`skill-badge-${index}`} className="col-12 col-xl-2">
                  <span className="skill-badge rounded-pill d-flex align-items-center justify-content-center py-2 px-4">
                    {item.icon}
                    <span className="ml-2">{item.name}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {sectionData.map((item, index) => (
        <Section
          key={`section-${index}`}
          id={item.id}
          title={item.title}
          content={item.content}
          backgroundColor={item.backgroundColor}
          buttonUrl={item.buttonUrl}
          imageUrl={item.imageUrl}
          orderByContent={item.orderByContent}
        />
      ))}
      <ContactForm />
    </div>
  );
};

export default Home;
