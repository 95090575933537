import React from "react";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-primary py-5 px-3">
      <div className="container">
        <div className="row text-white text-center">
          <div className="col-12">
            <div className="d-flex flex-column justify-content-center mb-4">
              <span className="fs-3 fw-bold mb-3">Let's Connect</span>
              <p>
                Whether it's about work or just to say hi, don't hesitate to get
                in touch!
              </p>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex flex-row justify-content-center">
              <Link
                to="mailto:connor.mccaffrey@gmail.com"
                className="footer-link mx-2"
              >
                <FaEnvelope size={24} color="white" />
              </Link>
              <Link
                to="https://github.com/CPMGameDev"
                className="footer-link mx-2"
              >
                <FaGithub size={24} color="white" />
              </Link>
              <Link
                to="https://www.linkedin.com/in/connor-mccaffrey-67b846a9/"
                className="footer-link mx-2"
              >
                <FaLinkedin size={24} color="white" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
