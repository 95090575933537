export const gameDevelopmentData = [
    {
        id: "card-tome",
        title: "Card Tome",
        description: "Card Tome is a 2D zelda-esque monster collector/deckbuilder. I've mixed monster collecting, card gameplay, top-down zelda-esque combat and overworld exploration.",
        tags: ["Game Design", "Pixel Art", "2D ARPG Monster Collector", "Deck Builder"],
        url: ""
    },
    {
        id: "spectraverse",
        title: "Spectraverse",
        description: "Spectraverse is a 2D local multiplayer turn-based strategy game. The player expands their interstellar empire by colonizing and capturing planets across the galaxy. To win, the player must be the first to own all the planets on the map.",
        tags: ["Game Design", "Pixel Art", "2D Turn-based Strategy"],
        url: ""
    },
    {
        id: "this-child-of-mine",
        title: "This Child of Mine",
        description: "I led the development of 'This Child of Mine,' a 2.5D adventure platformer published on Steam. The game was about a mother protecting her child during an alien invasion.",
        tags: ["Game Design", "3D Modeling", "2.5D Adventure Platformer"],
        url: ""
    },
    {
        id: "graviators",
        title: "Graviators",
        description: "Graviators is my capstone game project at QUT, it was a third-person arena based brawler. Choose your fighter, control your gravity and fight by using each characters' unique ranged and melee attacks.",
        tags: ["Game Design", "3D Modeling", "Third-Person Arena-Based Brawler"],
        url: ""
    },
    {
        id: "cyclone-taxi",
        title: "Cyclone Taxi",
        description: "Cyclone Taxi is a racing game based on Cyclone Debbie, where players control a taxi driver picking up passengers and dropping them off in a small town.",
        tags: ["Game Design", "3D Modeling", "3D Racing"],
        url: ""
    },
    {
        id: "Galaxy-Defender",
        title: "Galaxy Defender",
        description: "Galaxy Defender is a classic shoot 'em up game where the player battles endless waves of enemy ships.",
        tags: ["Game Design", "Pixel Art", "2D Shoot 'Em Up"],
        url: ""
    },
    {
        id: "Hero-Adventure",
        title: "Hero's Adventure",
        description: "Hero's Adventure is a platformer game where players navigate obstacles, battle enemies with patrolling AI, and aim to reach the end of each level alive.",
        tags: ["Game Design", "2D Side-Scrolling Platformer/Action"],
        url: ""
    },
    {
        id: "Portal-Jockeys",
        title: "Portal Jockeys",
        description: "Portal Jockeys is a stealth game where players must covertly steal intel from a corrupt futuristic company.",
        tags: ["Game Design", "3D Modeling", "Third-Person Stealth"],
        url: ""
    },
]