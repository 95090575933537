import { Link } from "react-router-dom";

interface SectionProps {
    id: string;
    title: string;
    content: string;
    buttonUrl: string;
    imageUrl: string;
    backgroundColor: string;
    orderByContent: boolean;
}

const Section = ({ id, title, content, buttonUrl, imageUrl, backgroundColor, orderByContent }: SectionProps) => {
    return (
        <section id={id} data-testid={id} className={`bg-${backgroundColor}`}>
            <div className="container py-5">
                <div className="row mx-auto">
                    <div className={`col-12 col-md-6 ${orderByContent ? "order-1" : "order-1 order-lg-2"}`}>
                        <div className="d-flex flex-column justify-content-lg-start justify-content-center text-start text-white">
                            <span className="fs-3 fw-bold pb-3 text-center text-lg-start">{title}</span>
                            <p>{content}</p>
                            <div className="d-grid d-md-block mt-3 mb-5 mb-lg-0 mt-lg-5 ">
                                <Link
                                    role="button"
                                    to={`/${buttonUrl}`}
                                    className="btn btn-danger"
                                    state={{ projectsType: "appDevelopment" }}
                                >
                                    View Projects
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-6 ${orderByContent ? "order-2" : "order-2 order-lg-1"}`}>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="d-flex flex-column justify-content-start text-start text-white">
                                <img src={imageUrl} className="img-fluid" alt={title} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section;