import React, { useState } from "react"

const Form = () => {
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const subject = `New message from ${name}`;
        const mailtoLink = `mailto:connor.mccaffrey@gmail.com?subject=${encodeURIComponent(subject)}&body=${message}`;
        window.location.href = mailtoLink;
    };

    return (
        <section className="background-container">
            <div className="container py-5 px-3">
                <div className="row">
                    <div className="col-12">
                        <form className="contact-form text-white" onSubmit={handleSubmit}>
                            <div className="form-group mb-4 text-center">
                                <span className="fs-1 fw-bold">Reach Out!</span>
                            </div>
                            <div className="form-group d-flex flex-column align-items-start mb-4">
                                <label className="fs-5 fw-bold mb-2">Name</label>
                                <input type="text" className="form-control" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)}></input>
                            </div>
                            <div className="form-group d-flex flex-column align-items-start mb-4">
                                <label className="fs-5 fw-bold mb-2">Message</label>
                                <textarea className="form-control" id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} style={{ height: "120px" }}></textarea>
                            </div>
                            <div className="form-group d-flex flex-column align-items-start mb-4">
                                <button type="submit" className="btn btn-outline-light px-5 py-2 rounded-5">Send</button>
                            </div>      
                        </form>
                    </div>
                </div>
            </div>    
        </section>
    )
}

export default Form;