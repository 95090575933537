import React from "react";
import { Outlet } from "react-router-dom";
import NavigationMenu from "../components/NavigationMenu";
import Footer from "../components/Footer";
import TopButton from "../components/TopButton";
import ScrollToTop from "../components/ScrollToTop";

const Layout = () => {
    return (
        <div className="bg-primary">
            <NavigationMenu />
            <Outlet />
            <TopButton />
            <ScrollToTop />
            <Footer />
        </div>
    )
}

export default Layout