interface ProjectCardProps {
    key: string;
    id: string;
    type: string;
    title: string;
    tags: string[];
    description: string;
    url?: string
  }  

  const ProjectCard = ({ key, id, type, title, tags, description, url }: ProjectCardProps) => {

    const formatTags = (tags: string[]) => tags.join(", ");
    const projectUrl = url !== "" ? url : `${type}/${id}`

    return (
        <div key={key} className="col">
            <div className="card bg-info">
                <img
                    src={`/images/projects/${type}/Project-${id}-Image-1.png`}
                    className="card-img-top"
                    alt={`Project ${id}`}
                />
                <div className="card-body text-white">
                    <span className="card-title fs-3">{title}</span>
                    <p className="card-text text-secondary">{formatTags(tags)}</p>
                    <p className="card-text">{description}</p>
                    <a href={projectUrl} className="btn btn-danger" target={url === "" ? `_self` : `_blank`}>
                        View Project
                    </a>
                </div>
            </div>
        </div>
    )
};

export default ProjectCard;