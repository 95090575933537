import { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { FaBars, FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const NavigationMenu = () => {
  const [show, setShow] = useState(false);
  const [isScrolling, setScroll] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.scrollingElement?.scrollTop;

      if (scrolled! >= 5) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        bg="primary"
        expand="lg"
        className={`navigation-menu px-2 px-md-4 sticky-top ${
          isScrolling ? "scrolling" : ""
        }`}
      >
        <Button
          variant="outline-primary"
          onClick={handleShow}
          className="me-auto border-0"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          <FaBars size={24} color={"white"} />
        </Button>
        <Navbar.Collapse id="navbar-nav">
          <Navbar.Brand href="/" className="text-white ms-auto">
            <img src="/images/signature.png" className="img-fluid" alt="" />
          </Navbar.Brand>
          <Nav className="ms-auto">
            <Nav.Link href="mailto:connor.mccaffrey@gmail.com">
              <FaEnvelope size={24} color={"white"} />
            </Nav.Link>
            <Nav.Link href="https://github.com/CPMGameDev">
              <FaGithub size={24} color={"white"} />
            </Nav.Link>
            <Nav.Link href="https://www.linkedin.com/in/connor-mccaffrey-67b846a9/">
              <FaLinkedin size={24} color={"white"} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div
        className={`offcanvas offcanvas-start w-100 bg-primary ${
          show ? "show" : ""
        }`}
        tabIndex={-1}
        id="offcanvas"
        aria-labelledby="offcanvasLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            onClick={handleClose}
          ></button>
        </div>
        <div className="offcanvas-body d-flex justify-content-center align-items-center">
          <ul className="nav flex-column text-center fs-2 fw-bold">
            <li className="nav-item">
              <Link to="/" className="nav-link" onClick={handleClose}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about-me" className="nav-link" onClick={handleClose}>
                About Me
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/app-development"
                className="nav-link"
                onClick={handleClose}
                state={{ projectsType: "appDevelopment" }}
              >
                App Development
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/ui-ux-mockups"
                className="nav-link"
                onClick={handleClose}
                state={{ projectsType: "uiUxDesign" }}
              >
                UI/UX Mockups
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/game-design"
                className="nav-link"
                onClick={handleClose}
                state={{ projectsType: "gameDevelopment" }}
              >
                Game Design
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact-us" className="nav-link" onClick={handleClose}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;
