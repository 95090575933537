export const uiUxMockupData = [
    {
        id: "hooked-dating",
        title: "Hooked Dating",
        description: "Created a visually appealing and user-friendly dating app UI/UX mockup using Adobe XD.",
        tags: ["Adobe XD"],
        url: "https://xd.adobe.com/view/46a78393-a7a6-4115-5553-36123a15f49e-3096/"
    },
    {
        id: "maynooth-furniture",
        title: "Maynooth Furniture",
        description: "Designed UI/UX mockups for a fictional furniture store using Adobe XD, for both desktop and mobile.",
        tags: ["Adobe XD"],
        url: "https://xd.adobe.com/view/e30b7e96-07b6-4e8e-5ae4-aeaf6f2825b1-953a/"
    },
    {
        id: "roar-cycles",
        title: "Roar Cycles",
        description: "Created a UI/UX mockup for a fictional bike store on Adobe XD.",
        tags: ["Adobe XD"],
        url: "https://xd.adobe.com/view/738dc1da-0af9-475d-574a-105507107b85-05bc/"
    }
]