import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ContactUs from "./pages/ContactUs";
import AboutMe from "./pages/AboutMe";
import { appDevelopmentData } from "./data/appDevelopmentData";
import Project from "./pages/Project";
import { gameDevelopmentData } from "./data/gameDesignData";

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about-me" element={<AboutMe /> } />
          <Route path="/app-development" element={<Projects /> } />
          {appDevelopmentData.map((element) => (
            <Route 
              key={element.id}
              path={`app-development/${element.id}`} 
              element={<Project projectId={element.id} projectName={element.title} projectTypeId="app-development" projectTypeName="App Development" />}
            />
          ))}
          <Route path="/ui-ux-mockups" element={<Projects /> } />
          <Route path="/game-design" element={<Projects /> } />
          {gameDevelopmentData.map((element) => (
            <Route 
              key={element.id}
              path={`game-design/${element.id}`} 
              element={<Project projectId={element.id} projectName={element.title} projectTypeId="game-design" projectTypeName="Game Design" />}
            />
          ))}
          <Route path="/contact-us" element={<ContactUs /> } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
